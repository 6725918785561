.card {
    width: 100%;
    padding: 16px;
    // bgcolor: 'background.paper',

    .header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .snack {
            display: flex;
            align-items: flex-start;

            grid-gap: 16px;

            .icon {
                width: 54px;
                height: 54px;
                background-color: var(--mui-palette-background-default);
                border-radius: var(--mui-shape-borderRadius);
                overflow: hidden;

                img {}
            }

            .title {
                .name {
                    font-size: 1.09rem;
                    line-height: 1.09rem;
                    color: var(--mui-palette-primary-main);
                    font-weight: bold;
                    padding-top: 1px;
                }

                .lessons {
                    display: flex;
                    font-size: 1.05rem;
                    align-items: center;
                    padding-top: 3px;

                    color: var(--mui-palette-text-secondary);

                    .svg {
                        margin-top: -3px;
                        color: var(--mui-palette-text-secondary);
                    }

                    b {
                        font-weight: normal;
                        padding-left: 8px;
                    }

                    span {
                        padding-left: 4px;
                    }
                }

            }
        }

        .stat {
            display: flex;

            .lessons {
                display: flex;
                align-items: center;
                grid-gap: 8px;
                color: var(--mui-palette-text-secondary);
                font-weight: bold;

                ._icon {

                    svg {
                        margin: 0 !important;
                        padding: 0 !important;
                    }
                }

            }
        }
    }

    .main {
        margin-top: 8px;

        .title {
            font-weight: bold;
        }

        .more {
            padding-top: 8px;
        }
    }

    .menu {
        margin-top: 24px;
        display: flex;
        justify-content: end;
        grid-gap: 16px;

        .link {}

        .open {}
    }
}