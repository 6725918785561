.card {
    width: 100%;
    padding: 24px 24px;

    .header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .courseHeader {
            width: 100%;
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            grid-gap: 16px;

            position: relative;

            .icon {
                width: 100px;
                height: 100px;
                overflow: hidden;
                border-radius: 50%;
                background-color: var(--mui-palette-background-default);

                img {}
            }

            .title {
                padding: 0px 0;
                font-weight: bold;
            }


            .stat {
                position: absolute;
                right: 0px;
                top: 0px;
            }

        }

    }

    .main {
        margin-top: 16px;

        .list {
            display: flex;
            flex-direction: column;
            row-gap: 18px;
            padding: 6px 0;

            .item {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .stack {
                    display: flex;
                    grid-gap: 12px;
                    align-items: center;
                    flex: 1;

                    .icon {
                        color: var(--mui-palette-action-active);
                    }

                    .name {
                        flex: 1;
                    }

                }

                .value {

                    display: flex;
                    grid-gap: 4px;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                    
                    // color: blue;
                    // font-weight: bold;

                    span {
                        font-size: 0.9rem;
                        color: black;
                        border-radius: 8px;
                        background-color: #87ffc99f;
                        padding: 0px 4px;
                    }
                }
            }
        }

        // .more {
        //     padding-top: 8px;
        // }
    }

    .menu {
        margin-top: 16px;
        display: flex;
        justify-content: end;
        grid-gap: 16px;

        .link {}

        .open {}
    }
}