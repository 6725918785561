.stack {
    display: flex;
    grid-gap: 16px;
    align-items: center;

    .grade {
        color: black;
        font-weight: bold;
        background-color: #ffb2235e;

        width: 24px;
        height: 24px;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 50%;
    }
}