.lesson {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 16px;

    .stack {
        display: flex;
        align-items: center;
        grid-gap: 16px;
    }
}