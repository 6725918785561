.user {

    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 8px 0;

    .number {
        font-weight: bold;
        background-color: #6b9ee269;

        width: 24px;
        height: 24px;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 8px;
    }

    .icon {
        padding-left: 16px;
    }

    .stack {
        flex: 1;

        padding-left: 16px;

        .name {
            font-weight: bold;
        }

        .info {
            font-size: 15px;
            padding-top: 0px;
        }
    }

    .grade {
        font-weight: bold;
        background-color: #ffb2235e;

        padding: 1px 16px;

        display: flex;
        align-items: center;
        justify-content: center;

        grid-gap: 8px;

        border-radius: 6px;
    }

}