*:not(input):not(textarea),
::after,
::before {
    /* -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none; */
}

html,
#root {
    height: 100%;
}

body {
    width: 100%;
    height: 100%;

    margin: 0;
    padding: 0px;

    font-family:
        -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Embed.WACFrame {
    border-color: black !important;
}

.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary {
    font-weight: 400 !important;
}

div {}

a {
    display: block;
    text-decoration: none;
    padding: 0;
    margin: 0;
}

img {
    display: block;
    padding: 0px;
    margin: 0px;
}

p {
    margin: 0px;
}

.svg-loader {
    animation: 3s linear infinite rotate;
}

/* header button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium {
    width: 44px !important;
    height: 44px !important;
    box-shadow: inset 0 0 0 1px #1068bf;
} */

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

#root {
    background: radial-gradient(farthest-corner circle at 0% 0%, var(--muidocs-palette-grey-50) 0%, var(--muidocs-palette-primary-50) 100%);
}

.svg-custom {
    margin: 0 !important;
    padding: 0 !important;
    display: block !important;
}