.group_item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    // padding: 16px;
    // border-bottom: 1px solid var(--mui-palette-divider);

    &:last-child {
        border-bottom: none !important;
    }

    .snack {
        display: flex;
        align-items: flex-start;
        grid-gap: 16px;

        .icon {

            width: 54px;
            height: 54px;
            background-color: var(--mui-palette-background-default);
            border-radius: var(--mui-shape-borderRadius);
            overflow: hidden;

            img {}
        }

        .title {

            .name {
                font-weight: bold;
            }

            .students {
                display: flex;
                font-size: 1.05rem;
                align-items: center;

                color: var(--mui-palette-text-secondary);

                .svg {
                    margin-top: -2px;
                    color: var(--mui-palette-text-secondary);
                }

                b {
                    font-weight: normal;
                    padding-left: 8px;
                }

                span {
                    padding-left: 4px;
                }
            }
        }

    }

    .stat {
    }
}