.header {
    h1 {
        color: gray;
        font-size: 1rem;
        margin: 0px !important;
        padding: 0px !important;
        text-align: center;
    }

    padding-bottom: 12px;
}

.icon {
    width: 128px;
    height: 128px;

    border-radius: 4px;
    border: 2px solid var(--mui-palette-divider);

    padding: 4px;

    box-sizing: border-box;

    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;

    &.active {
        // padding: 0px !important;
        // background-color: black;
    }

    img {
        border-radius: 4px;
        width: 104px;
    }

    input {
        cursor: pointer;
        opacity: 0;
        width: 100% !important;
        height: 100% !important;
        position: absolute;
        top: 0px;
        left: 0px;

        cursor: pointer;

    }

}

.group {

    &.flex {
        display: flex;
        justify-content: center;
    }

}