.lessons {
    .list {
        display: flex;
        flex-direction: column;

        .item {
            display: flex;
            justify-content: space-between;
            align-items: center;

            padding: 16px 10px;
            border-bottom: 1px solid var(--mui-palette-divider);
            overflow-y: auto;

            &:last-child {
                border-bottom: none;
            }

            div {
                padding: 0 6px;
            }

            .day {
                span {
                    font-size: 0.9rem;
                    color: black;
                    border-radius: 8px;
                    background-color: #87b3ffcf;
                    padding: 4px 10px;
                    font-weight: bold;
                }
            }

            .course {

            }
            .time {

            }

            .value {
                display: flex;
                grid-gap: 4px;
                flex-wrap: wrap;
                justify-content: flex-end;
            }
        }
    }
}