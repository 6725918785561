.pdfNoAccess {

    width: 100%;
    aspect-ratio: 16/9;
    height: auto;
    background-color: black;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    color: white;
    
    .icon {

    }

    .text {
        padding-top: 16px;
    }

}