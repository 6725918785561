.auth {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #EEEEF9;

    // default: '#EEEEF9',
    // paper: '#F9F9FE',

    .form {
        // width: 100%;
        padding: 34px 24px;
        border-radius: 8px;
        box-sizing: border-box;
        max-width: 380px;

        background-color: #F9F9FE;
        border: 1px solid #0000001f;

        color: #333238;

        .header {
            .icon {
                text-align: center;

                svg {}
            }

            h1 {
                margin: 1rem 0;
                font-size: 1.15rem;
                text-align: center;
            }
        }

        .oauth {
            .denied {
                padding: 2px 6px;
                padding-bottom: 3px;
                text-align: center;
                margin-bottom: 1rem;
                background-color: #dd2b0e;
                color: white;
                border-radius: 4px;
                font-weight: 500;
                font-size: 0.9rem;
            }

            .group {

                margin-bottom: 1rem;

                label {
                    font-weight: 600;
                    display: inline-block;
                    margin-bottom: 0.5rem;
                }

                input {
                    width: 100%;
                    border-radius: 4px;

                    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
                    font-size: 0.95rem;

                    line-height: 1rem;
                    padding: 0.5rem;

                    height: 40px;
                    box-sizing: border-box;

                    color: #333238;
                    box-shadow: inset 0 0 0 1px #89888d;

                    border-style: none;
                    appearance: none;
                }

                p {
                    color: #dd2b0e;
                    font-size: 0.9rem;
                }

                p.hidden {
                    display: none;
                }

            }

            .remember {
                margin-bottom: 1rem;
                display: flex;
                align-items: center;

                .checkbox {
                    cursor: pointer;

                    width: 20px;
                    height: 20px;
                    border-radius: 4px;
                    box-shadow: inset 0 0 0 1px #89888d;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &>div {
                        width: 0px;
                        height: 0px;
                        border-radius: 2px;
                        transition: 0.1s;
                    }

                    &.checked>div {
                        width: 12px;
                        height: 12px;
                        background-color: #000;
                    }
                }

                label {
                    cursor: pointer;
                    margin-left: 10px;
                    // font-weight: 500;
                    font-size: 0.95rem;
                    display: inline-block;
                }
            }

            button {
                cursor: pointer;

                &:active {
                    transform: scale(0.94);
                    transition: 0.2s;
                    font-size: 0.98rem;
                }

                width: 100%;
                border-width: 0px;

                height: 40px;

                color: #fff;
                background-color: #1f75cb;
                box-shadow: inset 0 0 0 1px #1068bf;

                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 0.95rem;
                font-weight: 550;
                border-radius: 0.25rem;
            }
        }

    }
}