.load {
    width: 100%;
    aspect-ratio: 16/9;
    height: auto;
    background-color: black;
}

.container {
    width: 100%;
    aspect-ratio: 16/9;
    height: auto;
    background-color: black;

    display: flex;
    align-items: center;
    justify-content: center;
}

.page {
    background-color: black;
}