.card {
    width: 100%;
    padding: 16px;

    .header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .snack {
            display: flex;
            align-items: flex-start;
            grid-gap: 16px;

            .icon {

                width: 54px;
                height: 54px;
                background-color: var(--mui-palette-background-default);
                border-radius: var(--mui-shape-borderRadius);
                overflow: hidden;

                img {}
            }

            .title {

                .name {
                    font-weight: bold;
                }

                .students {
                    display: flex;
                    font-size: 1.05rem;
                    align-items: center;
                    padding-top: 3px;

                    color: var(--mui-palette-text-secondary);

                    .svg {
                        margin-top: -2px;
                        color: var(--mui-palette-text-secondary);
                    }

                    b {
                        font-weight: normal;
                        padding-left: 8px;
                    }

                    span {
                        padding-left: 4px;
                    }
                }
            }

        }

        .stat {
            // display: flex;

            // .lessons {
            //     display: flex;
            //     align-items: center;
            //     grid-gap: 8px;
            //     color: var(--mui-palette-text-secondary);
            //     font-weight: bold;

            //     ._icon {

            //         svg {
            //             margin: 0 !important;
            //             padding: 0 !important;
            //         }
            //     }
            // }
        }
    }

    .main {
        margin-top: 16px;

        .list {
            display: flex;
            flex-direction: column;
            row-gap: 12px;
            padding: 4px 0;

            .item {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .stack {
                    display: flex;
                    grid-gap: 12px;
                    align-items: center;
                    flex: 1;

                    .icon {
                        color: var(--mui-palette-action-active);
                    }

                    .name {
                        flex: 1;
                    }

                }

                .value {

                    display: flex;
                    grid-gap: 6px;
                    flex-wrap: wrap;
                    justify-content: flex-end;

                    span {
                        font-size: 0.9rem;
                        color: black;
                        border-radius: 8px;
                        background-color: #87b3ffcf;
                        padding: 2px 6px;
                    }
                }
            }
        }

        // .more {
        //     padding-top: 8px;
        // }
    }

    .menu {
        margin-top: 16px;
        display: flex;
        justify-content: end;
        grid-gap: 16px;

        .link {}

        .open {}
    }
}